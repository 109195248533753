import { getUser, isBrowser, setOrUpdateRTdb } from '../services/firebase'
import {
  BB_ACCESS_CODE_PREFIX,
  BB_ACCESS_CODE_SECRET,
  BB_ADMIN,
  BB_APPOINTMENT,
  BB_APPOINTMENT_REQUEST_PREFIX,
  BB_DOCTOR,
  BB_HR,
  BB_INVOICE,
  BB_MEDICINEONLY_REQUEST_PREFIX,
  BB_MEDICINE_ONLY_REQUEST,
  BB_PHARMACIST,
  BB_SLACK_WEBHOOK_URL,
} from './constants'
import { VariantType } from 'notistack'

// Utility method for deciding if something should be allowed during development stage or not
export const allowDisallow = () => {
  return process.env.NODE_ENV !== 'development' // this will disallow during development
  // return process.env.NODE_ENV !== 'production'; // enabling this line will allow during development
  // return true // enabling this line will allow in all environments
}

export const generateProfileId = async ({
  data,
  profile,
}: {
  data: any
  profile?: string
}): Promise<string> => {
  let prefix
  switch (profile) {
    case BB_DOCTOR:
      prefix = 'bBD'
      break
    case BB_HR:
      prefix = 'bBH'
      break
    case BB_ADMIN:
      prefix = 'bB'
      break
    case BB_PHARMACIST:
      prefix = 'bBP'
      break
    case BB_APPOINTMENT:
      prefix = BB_APPOINTMENT_REQUEST_PREFIX
      break
    case BB_MEDICINE_ONLY_REQUEST:
      prefix = BB_MEDICINEONLY_REQUEST_PREFIX
      break
    case BB_INVOICE:
      prefix = 'BBINV'
      break
    case BB_ACCESS_CODE_SECRET:
      prefix = BB_ACCESS_CODE_PREFIX
      break
    default:
      prefix = 'bb' // for patients
      break
  }
  const num = Math.floor(Math.random() * 90000) + 10000
  const bBuid = `${prefix}${num}`

  const filteredData = getNameAddress({
    data,
    dataFilter: {
      key: 'id',
      value: bBuid,
    },
  })
  if (!filteredData) {
    await setOrUpdateRTdb({
      ref: `uids/${getTimestamp()}`,
      data: {
        id: bBuid,
      },
    })
    return `${prefix}${num}`
  }
  console.error('UNIQUE ID ALREADY EXISTS..RECURSIVELY CALLING...')
  // if exists, call it recursively
  handleWebHook({
    text: `Oops! ${bBuid} was already generated in the past. Generating again...`,
    channel: '#admins-only',
  })
  return await generateProfileId({ data, profile })
}

export const generateColorCode = () => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16)
  return `#${randomColor}`
}

export const addBodyClass = (className: string) =>
  document.body.classList.add(className)

export const removeBodyClass = (className: string) =>
  document.body.classList.remove(className)

export const getTimestamp = () => new Date().getTime()

export const getFutureDateAfter = (days: number) => {
  const newDate = new Date(new Date().getTime() + days * 24 * 60 * 60 * 1000)
  return newDate.getTime()
}

/* should return false if not expired */
export const isExpired = (dt: number): boolean => new Date(dt) < new Date()

export const getDateFromTS = (ts: number | string): string => {
  const dte = new Date(ts)
  return `${dte.toLocaleDateString()} ${new Date(ts).toLocaleTimeString()}`
}

export const todaysDate = (): string => {
  const dte = new Date()
  return `${dte.toLocaleDateString()} ${new Date().toLocaleTimeString()}`
}

export const getDateTS = (dt: string): string => {
  const dte = new Date(dt)
  return `${dte.getTime()}`
}

export const getUsername = (email: string) => {
  if (email && email.indexOf('@') === -1) return ''
  return email && email.split('@')[0]
}

export const bloomBfetch = async (uri: string, headers: any) => {
  try {
    const response = await fetch(uri, {
      headers,
    })
    const responseData = await response.json()
    return responseData
  } catch (err) {
    console.error(err)
    throw new err()
  }
}

export const lowerFirstChar = (str: string) =>
  `${str[0].toLowerCase()}${str.substring(1)}`

export const trimText = (text: string, limit: number) => {
  let trimmedText = text
  if (text.length > limit) {
    trimmedText = `${text.substring(0, limit)}...`
  }
  return trimmedText
}

export const getUrlParam = (url: string, param: string) => {
  const uri = new URL(url)
  return uri.searchParams.get(param)
}

/* Utility method for filtering a record from array of objects such as a patientsData*/
export const getNameAddress = ({
  data,
  dataFilter,
}: {
  data: any
  dataFilter: {
    key: string
    value: string
  }
}) => {
  console.log('NAMEADD', data)
  const filteredData = data.filter(
    (item: any) => item[dataFilter.key] === dataFilter.value
  )[0]
  return filteredData
}

/* eg : "ec33879b-54321b909-4346-b8bf-6f85fd08e3f8" - 54321 is the reverse of 12345 and then appending bBSEC */
/* A valid url = http://127.0.0.1:8000/app/payinvoice/2f3eb886-85532290d-4322-b611-3239f8146e73 */
export const extractUid = (uuid: string, prefix: string) =>
  `${prefix}${uuid
    .substr(uuid.indexOf('-') + 1, 5)
    .split('')
    .reverse()
    .join('')}`

/* This util will inject reversed 5 digit code after 1st occurance of - within uui eg : "ec33879b-54321b909-4346-b8bf-6f85fd08e3f8"*/
export const injectUid = (uuid: string, accessCode: string) => {
  var urlCodeArray = uuid.split('-').map((item, index) => {
    if (index === 1) {
      const reversedCode = accessCode
        .split('')
        .reverse()
        .join('')
      item = `${reversedCode}${item}`
    }
    return item
  })
  return urlCodeArray.join('-')
}

/* Send slack webhook message */
export const sendWebhookMsg = async ({
  text,
  channel = '#admins-only',
  username = 'bloomB',
}: {
  text: string
  channel?: string
  username?: string
}) => {
  try {
    const payload = JSON.stringify({
      text,
      channel,
      username,
      icon_emoji: ':ghost:',
    })
    const response = await fetch(BB_SLACK_WEBHOOK_URL, {
      method: 'POST',
      body: payload,
      headers: {
        Accept: 'application/json',
      },
    })
  } catch (err) {
    console.error(err)
  }
}

export const handleWebHook = async ({
  text,
  channel,
  username,
}: {
  text: string
  channel?: string
  username?: string
}) => {
  const user = getUser()
  if (allowDisallow()) {
    await sendWebhookMsg({
      text,
      channel,
      username:
        Object.entries(user).length && user?.accessProfile
          ? getUsername(user?.accessProfile?.username)
          : 'bloomB',
    })
  }
}

export const getBitlyUrl = async (bloomBUrl: string, bitlyInstance: any) => {
  if (bloomBUrl) {
    let result
    try {
      result = await bitlyInstance.shorten(bloomBUrl)
      console.log('BLOGGG URL', result)
      return result.link || bloomBUrl
    } catch (e) {
      console.error(e)
      return bloomBUrl
    }
  }
}

export const showNotification = ({
  enqueueSnackbar,
  text,
  type = 'info',
}: {
  enqueueSnackbar: any
  text: string
  type?: VariantType
}) => {
  // variant could be success, error, warning, info, or default
  enqueueSnackbar(text, { variant: type })
}

// Utility for setting session Storage
export const setSessionStorage = (name: string, value: string) => {
  isBrowser() && window.sessionStorage.setItem(name, JSON.stringify(value))
}

// Utility for setting session Storage
export const removeSessionStorage = (name: string) => {
  isBrowser() && window.sessionStorage.removeItem(name)
}

// Utility for retrieving session Storage
export const getSessionStorage = (name: string) =>
  isBrowser() && window.sessionStorage.getItem(name)
    ? JSON.parse(window.sessionStorage.getItem(name))
    : {}

// For Sorting array of objects based on Timestamp of Google data
export const sortData = ([...arrayOfObjects]: any[], order?: string) => {
  const sorted = arrayOfObjects.sort(function(a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    const dateaTS = a['Timestamp'].split('/')
    const dateA = new Date(
      Date.parse(`${dateaTS[1]}/${dateaTS[0]}/${dateaTS[2]}`)
    )

    const datebTS = b['Timestamp'].split('/')
    const dateB = new Date(
      Date.parse(`${datebTS[1]}/${datebTS[0]}/${datebTS[2]}`)
    )

    if (order === 'ASC') return dateA - dateB
    return dateB - dateA
  })
  return sorted
}

export const detectRequestType = (requestId: string) => {
  if (requestId.includes(BB_APPOINTMENT_REQUEST_PREFIX)) {
    return 'appointments'
  } else if (requestId.includes(BB_MEDICINEONLY_REQUEST_PREFIX)) {
    return 'medicineOnlyRequests'
  }
}
