import { BitlyClient } from 'bitly'
import React, { useEffect } from 'react'
import { useReducer } from 'react'
import { getUser, isLoggedIn, updateLoginTime } from '../../services/firebase'
import { getUsername, removeSessionStorage, handleWebHook } from '../../utils'
import { BB_BITLY_ACCESS_TOKEN, BB_SS_MESSAGE } from '../../utils/constants'
import { SnackbarProvider } from 'notistack'

export const GlobalStateContext = React.createContext({})
export const UserDispatchContext = React.createContext({})

export interface IContextState {
  theme: string
  message?: {
    type: string
    text: string
  }
  user: {
    name: string
    uid?: string
    photo?: string
  }
  loggedin: boolean
  accessProfile?: {
    [key: string]: string
  }
  bBitly?: any
}
const initialState: IContextState = {
  theme: 'default',
  loggedin: false,
  user: {
    name: 'Visitor',
  },
}

const reducer = (state: IContextState, action: any) => {
  switch (action.type) {
    case 'TEST_CONTEXT':
      return {
        ...state,
        theme: 'bloomB',
      }
    case 'MESSAGE':
      return {
        ...state,
        message: action.payload,
      }
    case 'MESSAGE_RESET':
      return {
        ...state,
        message: {},
      }
    case 'SET_LOGGEDIN': //action when a user logs into the app irrespective of doctor/hr/pharmacist role
      const { displayName, photoURL, uid } = action.payload
      return {
        ...state,
        user: {
          uid: uid,
          name: displayName,
          photo: photoURL,
        },
        loggedin: true,
      }
    case 'SET_PROFILE': //action when a user accesses dashboard for setting up his profile
      // also sets the contentful author photo for internal profiles
      console.log('FROM CONTEXT', action.payload)
      return {
        ...state,
        accessProfile: action.payload,
        user: {
          ...state.user,
          // photo:
          //   action.payload.profile === WISMIM_ACCESS_MODE_INTERNAL &&
          //   action.payload.profileApproval
          //     ? action.payload.contentfulAuthorPhoto
          //     : state.user.photo,
        },
      }

    case 'LOGOUT':
      return {
        ...state,
        user: {
          name: 'Visitor',
        },
        loggedin: false,
        // accessProfile: {
        //   profile: WISMIM_ACCESS_MODE_PUBLIC,
        // },
        message: 'Successfully logged out',
      }
    case 'SET_BITLY':
      const bBitly = new BitlyClient(BB_BITLY_ACCESS_TOKEN, {})
      return {
        ...state,
        bBitly,
      }
    default:
      throw new Error(`Unknown action - ${action.type}`)
  }
}

const GlobalContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  console.log('GLOBAL STATE', state)

  useEffect(() => {
    if (isLoggedIn()) {
      const user = getUser()
      if (user && user.accessProfile) {
        dispatch({ type: 'SET_LOGGEDIN', payload: user })
        handleWebHook({
          text: `${getUsername(
            user.accessProfile.username
          )} reloaded & just logged in...`,
        })
        updateLoginTime(user.uid) //also update new login time
        if (user.accessProfile) {
          dispatch({
            type: 'SET_PROFILE',
            payload: user.accessProfile,
          })
          // setTimeout(() => {
          //   addBodyClass(BB_BODY_CLASS)
          // }, 1000)
        }
        // clear bloomB message
        removeSessionStorage(BB_SS_MESSAGE)
      }
    }
  }, [])

  return (
    <GlobalStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        <SnackbarProvider maxSnack={3}>{children}</SnackbarProvider>
      </UserDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
