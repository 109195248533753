import firebase from 'gatsby-plugin-firebase'
import { IContextState } from '../components/context/GlobalContextProvider'
import {
  allowDisallow,
  generateColorCode,
  generateProfileId,
  getTimestamp,
  getUsername,
  handleWebHook,
} from '../utils'
import {
  BB_ADMIN,
  BB_APPOINTMENT_REQUEST_PREFIX,
  BB_CASE_HISTORY_GOOGLESHEET_ID,
  BB_LS_BLOOMB_USER,
  BB_MEDICINEONLY_REQUEST_PREFIX,
  BB_STATUS_CONSULTATION_COMPLETE,
  BB_STATUS_RECORDED_CASE_HISTORY,
} from '../utils/constants'
import Tabletop from 'tabletop'

export const getUser = () =>
  isBrowser() && window.localStorage.getItem(BB_LS_BLOOMB_USER)
    ? JSON.parse(window.localStorage.getItem(BB_LS_BLOOMB_USER))
    : {}

export const isLoggedIn = () => {
  const user = getUser()
  return !!user.email
}

export const bloomBAnonymousLogin = () => {
  const auth = firebase.auth()
  return auth.signInAnonymously()
}

export const bloomBRegistration = ({
  emailAddress,
  firstName,
  lastName,
  password,
  profile,
  photoURL,
}: {
  emailAddress: string
  password: string
  firstName: string
  lastName: string
  profile: string
  photoURL: string
}): Promise<any> => {
  const auth = firebase.auth()
  return auth
    .createUserWithEmailAndPassword(emailAddress, password)
    .then((user: any) => {
      console.log('Successfully Registered!', user)
      const currentUser: any = auth.currentUser
      currentUser.sendEmailVerification()
      console.log('Successfully sendEmailVerification!', currentUser)
      currentUser.updateProfile({
        displayName: `${firstName} ${lastName}`,
        photoURL,
      })
      console.log('Successfully updateProfile!', currentUser)
      return currentUser
    })
    .then(async user => {
      const generatedProfileId = await generateProfileId({ data: [], profile })
      firebase
        .database()
        .ref('users/' + auth.currentUser.uid)
        .set({
          username: user.email,
          profilePhoto: photoURL,
          profile,
          profileApproval: false,
          profileId: generatedProfileId,
          colorCode: generateColorCode(),
          totalEarning: 0,
        })
        .then(res => {
          console.log('Successfully updated accessprofile!', res)
          console.log(res)
        })
      // logout({});
      return
      // return auth.currentUser;
    })
}

export const isBrowser = () => typeof window !== 'undefined'

export const setUser = (user: any) =>
  isBrowser() &&
  window.localStorage.setItem(BB_LS_BLOOMB_USER, JSON.stringify(user))

export const logout = (e: any) => {
  return new Promise(resolve => {
    firebase
      .auth()
      .signOut()
      .then(function() {
        setUser({})
        // removeBodyClass(BB_BODY_CLASS)
        resolve({})
      })
  })
}

export const updateUserLoginLogoutActivity = ({
  ref,
  data,
}: {
  ref: string
  data: number
}): Promise<any> => {
  if (allowDisallow()) {
    console.log('update Logout UserActivity')
    const fbdb = firebase.database().ref(ref)
    return fbdb.set(data)
  }
  return Promise.resolve('Success')
}

export const updateLoginTime = (uid: string) => {
  // updates last login date and time when you logout
  updateUserLoginLogoutActivity({
    ref: `activites/${uid}/lastlogin`,
    data: new Date().getTime(),
  })
}

export const bloomBLogin = ({
  emailAddress,
  password,
}: {
  emailAddress: string
  password: string
}): Promise<any> => {
  return firebase
    .auth()
    .signInWithEmailAndPassword(emailAddress, password)
    .then((res: any) => {
      //also update new login time
      updateLoginTime(res.user.uid)
      // console.log(res, auth.currentUser);
      console.log('AUTHENTICATED USER', res.user)
      setUser(res.user)
      return res.user
    })
}

export const setUserProfileLS = (data: any): void => {
  const user = getUser()
  let localUser: IContextState
  //if logged in and if the local storage has already been set with accessProfile data & if profile is not the same as before
  if (
    (!!user.email && !user.hasOwnProperty('accessProfile')) ||
    (user.hasOwnProperty('accessProfile') &&
      user.accessProfile.profile !== data.profile)
  ) {
    localUser = { ...user, accessProfile: data }
    window.localStorage.setItem(BB_LS_BLOOMB_USER, JSON.stringify(localUser))
  }
}

export const requestPasswordReset = ({
  emailAddress,
}: {
  emailAddress: string
}): Promise<any> => {
  return firebase.auth().sendPasswordResetEmail(emailAddress)
}

export const isApprovedRole = (roles: string[]) => {
  const user = getUser()
  let profiles: string[] = []
  if (
    user.accessProfile &&
    user.accessProfile.profile &&
    user.accessProfile.profileApproval
  ) {
    profiles = user.accessProfile.profile.split(', ')
  }
  return profiles.some(profileRole => roles.includes(profileRole))
}

export const isAdministrator = () => {
  const user = getUser()
  return (
    user.accessProfile &&
    user.accessProfile.profile === BB_ADMIN &&
    user.accessProfile.profileApproval
  )
}

export const handlePharmaHelp = (data: any, status: any) => {
  const requestId = data.id
  if (status === BB_STATUS_CONSULTATION_COMPLETE) {
    if (requestId.search(BB_APPOINTMENT_REQUEST_PREFIX) !== -1) {
      firebase
        .database()
        .ref(`appointments`)
        .once('value', snapshot => {
          let patientId: string = ''
          snapshot.forEach(item => {
            console.log('PHARMA', item)
            const filtered = Object.values(item.val()).filter(
              caseItem => caseItem['id'] && caseItem['id'] === requestId
            )
            if (filtered.length) {
              patientId = filtered[0]['patientId']
              return
            }
          })
          if (patientId.search('bb') === 0) {
            firebase
              .database()
              .ref(`patients`)
              .once('value', snapshot => {
                console.log('handlePharmaHelp', patientId)
                snapshot.forEach(item => {
                  console.log('ULTA', item)
                  if (item.val()['id'] === patientId) {
                    const msg = `A bloomb Doctor has just got finished off with her consultation !
                    *Note*: This message will speed up medicine dispensing as Pharmacist can directly pick up Patient details from this message.
                    Please reach out to Doctor for Rx details.

                    Details are as follows :
                    - Name : *${item.val()['name']}*
                    - Address : *${item.val()['address']}*
                    - Mobile : *${item.val()['mobile']}*
                    `
                    handleWebHook({
                      text: msg,
                      channel: '#doctors-corner',
                    })
                    return
                  }
                })
              })
          }
        })
    }
    // else if (requestId.search(BB_MEDICINEONLY_REQUEST_PREFIX) !== -1) {
    //   firebase
    //     .database()
    //     .ref(`medicineOnlyRequests`)
    //     .once('value', snapshot => {
    //       snapshot.forEach(item => {
    //         const filtered = Object.values(item.val()).filter(
    //           caseItem => caseItem['Request ID'] && caseItem['id'] === requestId
    //         )
    //         if (filtered.length) {
    //           return filtered['patientId']
    //         }
    //       })
    //     })
    //     .then((patientId: string) => {
    //       firebase
    //         .database()
    //         .ref(`patients`)
    //         .once('value', snapshot => {
    //           console.log('handlePharmaHelp', patientId)
    //           snapshot.forEach(item => {
    //             const msg = `A bloomb Doctor has just got finished off with her consultation !
    //       *Note*: This message will speed up medicine dispensing as Pharmacist can directly pick up Patient details from this message.
    //       Please reach out to Doctor for Rx details.

    //       Details are as follows :
    //       - Name : *${item.val()['name']}*
    //       - Address : *${item.val()['address']}*
    //       - Mobile : *${item.val()['mobile']}*
    //       `
    //             handleWebHook({
    //               text: msg,
    //               channel: '#admins-only',
    //             })
    //           })
    //         })
    //     })
    // }
  } else if (status === BB_STATUS_RECORDED_CASE_HISTORY) {
    Tabletop.init({
      key: BB_CASE_HISTORY_GOOGLESHEET_ID,
      callback: googleData => {
        console.log('google sheet data --->', googleData)
        const filtered = googleData.filter(
          gItem => gItem['Request ID'] === requestId
        )
        if (filtered.length) {
          const patientId = filtered[0]['Patient ID']
          if (patientId.search('bb') === 0) {
            firebase
              .database()
              .ref(`patients`)
              .once('value', snapshot => {
                console.log('handlePharmaHelp', patientId)
                snapshot.forEach(item => {
                  console.log('ULTA', item)
                  if (item.val()['id'] === patientId) {
                    const msg = `${
                      filtered[0]["Doctor's bloomB ID"]
                    } just recorded a case history!
          *Note*: This message will speed up medicine dispensing as Pharmacist does not have to wait for prescription to be generated.
          But double checking with the doctor is always recommended for matching the case details.
          Details are as follows :

          - Name : *${item.val()['name']}*
          - Address : *${item.val()['address']}*
          - Mobile : *${item.val()['mobile']}*
          - Rx : *${filtered[0]['Rx']}*
          `
                    handleWebHook({
                      text: msg,
                      channel: '#doctors-corner',
                    })

                    return
                  }
                })
              })
          }
        }
      },
      simpleSheet: true,
    })

    // firebase
    //   .database()
    //   .ref(`casehistory`)
    //   .once('value', snapshot => {
    //     snapshot.forEach(item => {
    //       const filtered = Object.values(item.val()).filter(
    //         caseItem =>
    //           caseItem['Request ID'] && caseItem['Request ID'] === requestId
    //       )
    //       if (filtered.length) {
    //         const msg = `${filtered[0]["Doctor's bloomB ID"]} just recorded a case history!
    //         *Note*: This message will speed up medicine dispensing as Pharmacist does not have to wait for prescription to be generated.
    //         But double checking with the doctor is always recommended for matching the case details.
    //         Details are as follows :
    //         - Name : *${filtered[0]['name']}*
    //         - Address : *${filtered[0]['address']}*
    //         - Mobile : *${filtered[0]['mobile']}*
    //         - Rx : *${filtered[0]['Rx']}*
    //         `
    //         handleWebHook({
    //           text: msg,
    //           channel: '#doctors-corner',
    //         })
    //       }
    //     })
    //   })
  }
}

export const handleStatusHook = (data: any, status: any) => {
  // switch case for different updations
  // update only if status code is retrievable
  const requestId = data.id
  const updatedBy = data.updatedBy

  // get status codes
  let statusDescription: string = ''
  firebase
    .database()
    .ref(`workflowLevels`)
    .once('value', snapshot => {
      const workflowLevelsArray: { code: string; description: string }[] = []
      snapshot.forEach(item => {
        workflowLevelsArray.push({
          code: item.key as string,
          description: item.val(),
        })
      })
      const statusObject = workflowLevelsArray.filter(
        item => Number(item.code) === status
      )
      statusDescription = statusObject[0]?.description
      console.log('NEW STATUS : ', statusDescription)
    })
    .then(() => {
      firebase
        .database()
        .ref(`workflow/${requestId}/${getTimestamp()}`)
        .set({
          status,
          updatedBy,
          // remarks :
        })
        .then(() => {
          firebase
            .database()
            .ref(`status/${requestId}`)
            .set({
              status,
              updatedAt: getTimestamp(),
              // remarks :
            })
            .then(() => {
              handleWebHook({
                text: `Case # ${requestId} moved to new stage - ${
                  statusDescription ? statusDescription : status
                }`,
                channel:
                  status > 400 && status < 500 ? '#admins-only' : '#general', // payment status series is in 400s - so display only in admin channel
              })
            })
          // if (enqueueSnackbar) {
          //   showNotification({
          //     enqueueSnackbar,
          //     text: 'Case moved to ... stage',
          //     type: 'info',
          //   })
          // }
        })
    })
}

// Generic function for setting db but not updating
export const setOrUpdateRTdb = ({
  ref,
  data,
  executeHook,
}: {
  ref: string
  data: any
  executeHook?: {
    current: number
  }
}): Promise<any> => {
  const fbdb = firebase.database().ref(ref)
  console.log('HOOOOOK excute hook', executeHook)
  return fbdb.set(data).then(() => {
    if (executeHook && executeHook.hasOwnProperty('current')) {
      handleStatusHook(data, executeHook?.current)
    }
  })
}

export const getLoggedInUsername = () => {
  const user = getUser()
  return getUsername(user?.accessProfile?.username)
}

// to update Queue status of a pending request
export const updateQueue = async ({
  data,
  id,
  type,
  from,
  to,
}: {
  data: any
  id: string
  type: string
  from: string
  to: string
}) => {
  const filteredQueueData = data.filter(
    (item: any) =>
      item.status === from && item.patientId === id && item.requestType === type
  )
  console.log('PATIENT 2', filteredQueueData)
  if (filteredQueueData.length && filteredQueueData[0].key) {
    const keyname = filteredQueueData[0].key
    delete filteredQueueData[0]['key']
    const queueResponse = await setOrUpdateRTdb({
      ref: `queue/${keyname}`,
      data: {
        ...filteredQueueData[0],
        status: to,
      },
    })
  }
}
