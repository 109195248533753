export const BB_BLOGS = 'blogs'
export const BB_BASE_URL = 'https://www.bloomb.co.in'
export const BB_LS_BLOOMB_USER = 'bloomb_user'
export const BB_SS_MESSAGE = 'bloomb_message'

export const BB_BODY_CLASS = 'theBloomBunch'

export const BB_DOCTOR = 'doctor'
export const BB_HR = 'hr'
export const BB_PHARMACIST = 'pharmacist'
export const BB_PATIENT = 'patient'
export const BB_ADMIN = 'bloomB'

export const BB_APPOINTMENT = 'appointment'

export const BB_CASE_HISTORY_FORM = 'https://forms.gle/vJrHjyiNqDtmbjWn8'
export const BB_CASE_FOLLOWUP_FORM = 'https://forms.gle/6RJ7B1XQivYfQeED7'
export const BB_CLINIC_PRESCRIPTION_FORM = 'https://forms.gle/kbfrqb8oABk6kzkv6'
export const BB_QUICK_REFERENCES_FORM = 'https://forms.gle/SgDtC8bwbeMAuBeF9'
export const BB_PATIENT_INVOICE_FORM = 'https://forms.gle/KiPc93Kf62we2mM5A'
export const BB_PATIENT_DOCUMENT_UPLOAD_FORM =
  'https://forms.gle/qYdkbyf23U3HzBwKA'

export const BB_REGISTERED_DATE = 'Registered Date'

export const BB_APPOINTMENT_REQUEST = 'APPREQ'
export const BB_MEDICINE_ONLY_REQUEST = 'MEDONLYREQ'
export const BB_ACCESS_CODE_SECRET = 'ACCESS_CODE'

export const BB_INVOICE = 'INVOICE'

export const BB_PENDING_STATUS = 'pending'
export const BB_DONE_STATUS = 'done'

export const BB_ADMIN_USERNAME = 'admin'

export const BB_ACCESS_CODE_PREFIX = 'bBSEC'
export const BB_APPOINTMENT_REQUEST_PREFIX = 'bBAPP'
export const BB_MEDICINEONLY_REQUEST_PREFIX = 'bBMED'

export const BB_CASE_HISTORY_GOOGLESHEET_ID =
  '1q6e2okd7Rr52heo7stjrHkZH4Vos7Fwywwy_2QibXAM'
export const BB_CLINIC_PRESCRIPTIONS_GOOGLESHEET_ID =
  '1PsyCEZjG19z58Rf0QvVm4wG41sUO78X9JMzIbYzQUX4'
export const BB_QUICK_REFERENCES_GOOGLESHEET_ID =
  '1JUp_ETU9VwWsCTv_Sl3Kv9Gaw4jHpYos744R55-HcUU'
export const BB_CASE_FOLLOWUP_GOOGLESHEET_ID =
  '1wXek6IF9hJ3kboC-Ql8OskJYTEGn_wSWzwEuvxD69dQ'
export const BB_PATIENT_DOCUMENT_GOOGLESHEET_ID =
  '13TfL7Hye7EziHUj2sv_LgAKyisko7KJZ3Q0nHLdCkKE'

export const BB_SLACK_WEBHOOK_URL =
  'https://hooks.slack.com/services/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'

export const BB_REGISTER_TYPEFORM_URL =
  'https://numwvdipthf.typeform.com/to/O5l4Np03'
export const BB_TRACK_JOURNEY_TYPEFORM_URL =
  'https://numwvdipthf.typeform.com/to/qZaDZBEu'

export const BB_BITLY_ACCESS_TOKEN = '66722ecb570f34cdf3a51ce6497b2107dd664a41'

// Below Status updates are automated
export const BB_STATUS_APPOINTMENT_FIXED = 100
export const BB_STATUS_PRESCRIPTION_GEN_BY_DOCTOR = 204
export const BB_STATUS_PRESCRIPTION_GEN_BY_PHARMACIST = 240
export const BB_STATUS_PAYMENT_LINK_GENERATED = 401
export const BB_DOCUMENT_DOWNLOAD_LINK_GENERATED = 699
export const BB_STATUS_PAYMENT_COMPLETE = 410
export const BB_STATUS_MEDONLY_REQUEST_INITIATED = 58

// Below are manual updations required type
export const BB_STATUS_CONSULTATION_COMPLETE = 105
export const BB_STATUS_NO_SHOW = 103
export const BB_STATUS_RECORDED_CASE_HISTORY = 201
export const BB_STATUS_MEDICINE_DISPENSED_DESPATCHED = 300
export const BB_STATUS_MEDICINE_TRACKING_INFORMED = 501
export const BB_STATUS_MEDICINE_PRESCRIPTION_UPLOADED = 304
export const BB_STATUS_PAYMENT_DUE_INFORMED = 402
export const BB_STATUS_PAYMENT_DEFERRED = 404 // Pay later
export const BB_STATUS_FEEDBACK_REQUESTED = 505
export const BB_STATUS_FEEDBACK_RECEIVED = 508
export const BB_STATUS_CASE_CLOSED = 786
